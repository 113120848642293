export function getServiceUuidFromSlug(slug?: string | string[]): string | undefined {
  if (slug === undefined) {
    return undefined;
  }

  const uuid = slug.toString().slice(-8);

  if (/^([a-zA-Z_\-\d]{8})$/.test(uuid)) {
    return uuid;
  }
}
